import moment from "moment";
import React, { useCallback, useEffect, useMemo } from "react";

import { ISO_DATE_FORMAT } from "legacy/constants/WidgetValidation";
import { DatePickerType } from "legacy/widgets/DatePickerWidget/Constants";
import DatePickerComponent from "legacy/widgets/DatePickerWidget/DatePickerComponent";
import { CompactMode, EditProps } from "../Constants";
import { DateCellWrapper, Position } from "./Shared";
import type { TableCellProps } from "../TableUtilities";

const EditDateCell = (props: {
  editProps: EditProps;
  value: any;
  inputPosition: Position;
  compactMode: CompactMode;
  cellProps: TableCellProps;
}) => {
  const { value, editProps, inputPosition } = props;
  const { handleEditStop, editMinDate, editMaxDate, editTwentyFourHourTime } =
    editProps;

  const {
    inputFormat,
    outputFormat,
    timezone,
    manageTimezone,
    displayTimezone,
  } = props.cellProps;

  const handleValueChange = useCallback(
    (date: any) => {
      handleEditStop({ shouldSave: true, value: date, validationErrors: [] });
    },
    [handleEditStop],
  );

  const handleDatepickerClose = useCallback(
    () => handleEditStop({ shouldSave: false }),
    [handleEditStop],
  );

  useEffect(() => {
    return () => {
      // stop editing w/o saving when component unmounts
      handleEditStop({ shouldSave: false });
    };
  }, [handleEditStop]);

  const formattedMinDate = useMemo(() => {
    if (editMinDate) {
      return moment(editMinDate, ISO_DATE_FORMAT).format(ISO_DATE_FORMAT);
    }
  }, [editMinDate]);

  const formattedMaxDate = useMemo(() => {
    if (editMaxDate) {
      return moment(editMaxDate, ISO_DATE_FORMAT).format(ISO_DATE_FORMAT);
    }
  }, [editMaxDate]);

  return (
    <DateCellWrapper
      $position={inputPosition}
      $compactMode={props.compactMode}
      cellProperties={props.cellProps.cellProperties}
    >
      <DatePickerComponent
        label={""}
        dateFormat={inputFormat}
        displayDateFormat={outputFormat}
        widgetId={"unused"}
        datePickerType={DatePickerType.DATE_PICKER}
        onDateSelected={handleValueChange}
        isLoading={false}
        isDisabled={false}
        selectedDate={value}
        forceOpen={true}
        onDatePickerClosed={handleDatepickerClose}
        showCalendarIcon={false}
        twentyFourHourTime={editTwentyFourHourTime === true}
        minDate={formattedMinDate}
        maxDate={formattedMaxDate}
        timezone={manageTimezone ? timezone : undefined}
        displayTimezone={manageTimezone ? displayTimezone : undefined}
        showTimezone={false}
      />
    </DateCellWrapper>
  );
};

export default EditDateCell;
