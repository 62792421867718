import { call } from "redux-saga/effects";
import SigningKeyRotationApi from "legacy/api/SigningKeyRotationApi";
import { sendErrorUINotification } from "utils/notification";
import { callSagas, createSaga } from "../../../utils/saga";
import slice from "../slice";
import { getKeyRotations } from "./getKeyRotation";

export function* cancelKeyRotationInternal({
  rotationId,
}: {
  rotationId: string;
}) {
  try {
    yield call(SigningKeyRotationApi.cancelRotation, rotationId);
  } catch (e: any) {
    sendErrorUINotification({
      message: e?.message ? e.message : `Error canceling resource signing`,
    });
    return;
  }
  yield callSagas([getKeyRotations.apply({})]);
}

export const cancelKeyRotation = createSaga(
  cancelKeyRotationInternal,
  "cancelKeyRotation",
  {
    sliceName: "agents",
  },
);

slice.saga(cancelKeyRotation, {
  start(state, { payload }) {
    delete state.errors.keyRotations;
  },
  success(state, { payload }) {
    delete state.errors.keyRotations;
  },
  error(state, { payload }) {
    state.errors.keyRotations = { error: payload };
  },
});
