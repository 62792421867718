import { updateWidgetProperties } from "legacy/actions/controlActions";
import {
  openEditorTab,
  setEditorHeight,
} from "legacy/actions/editorPreferencesActions";
import { restartEvaluation } from "legacy/actions/evaluationActions";
import {
  resetChildrenMetaProperty,
  resetWidgetMetaProperty,
  setMetaProp,
  setMetaProps,
} from "legacy/actions/metaActions";
import {
  updateLayout,
  updateLayoutPosition,
  updatePartialLayout,
} from "legacy/actions/pageActions";
import {
  updateActivePropertyInPropertyPane,
  updateHoveredPropertyInPropertyPane,
} from "legacy/actions/propertyPaneActions";
import { focusWidget, selectWidgets } from "legacy/actions/widgetActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";

export const DEPLOYED_ACTIONS: string[] = [
  ReduxActionTypes.SET_EVALUATED_TREE,
  ReduxActionTypes.SET_LOADING_ENTITIES,
  ReduxActionTypes.UPDATE_RESPONSIVE_CANVAS_METADATA,
  resetWidgetMetaProperty.type,
  resetChildrenMetaProperty.type,
  setMetaProp.type,
  setMetaProps.type,
  updateLayoutPosition.type,
  ReduxActionTypes.SET_DYNAMIC_WIDGET_PROPS,
  ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_HEIGHT,
  ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_WIDTH,

  // Multipage
  ReduxActionTypes.INIT_CANVAS_LAYOUT,
  ReduxActionTypes.RESET_WIDGETS,
  restartEvaluation.type,
  ReduxActionTypes.STARTED_PAGE_LOAD_APIS,
];

export const EDIT_ACTIONS: string[] = [
  ...DEPLOYED_ACTIONS,
  updatePartialLayout.type,
  updateWidgetProperties.type,
  updateLayout.type,
  selectWidgets.type,
  focusWidget.type,
  updateHoveredPropertyInPropertyPane.type,
  updateActivePropertyInPropertyPane.type,
  ReduxActionTypes.RENAME_WIDGETS,
  ReduxActionTypes.SET_LOCAL_DEV_MODE,
  ReduxActionTypes.SET_LOCAL_DEV_SERVER_STATUS,
  ReduxActionTypes.SET_WIDGET_DRAGGING,
  ReduxActionTypes.WIDGET_OPERATION_COMPLETE,

  // The following are used just to populate the API Pane that we render below.
  setEditorHeight.type,
  openEditorTab.type,
];
