import { call } from "redux-saga/effects";
import SigningKeyRotationApi from "legacy/api/SigningKeyRotationApi";
import { sendErrorUINotification } from "utils/notification";
import { callSagas, createSaga } from "../../../utils/saga";
import slice from "../slice";
import { getKeyRotations } from "./getKeyRotation";

export function* startKeyRotationsInternal() {
  try {
    const resp: {
      responseMeta: { status: number; message: string; success: boolean };
    } = yield call(SigningKeyRotationApi.startRotation);
    if (resp && resp?.responseMeta?.success === false) {
      throw new Error(resp?.responseMeta?.message);
    }
  } catch (e: any) {
    sendErrorUINotification({
      message: e?.message ? e.message : `Error starting resource signing`,
    });
    return;
  }
  yield callSagas([getKeyRotations.apply({})]);
}

export const startKeyRotation = createSaga(
  startKeyRotationsInternal,
  "startKeyRotation",
  {
    sliceName: "agents",
  },
);

slice.saga(startKeyRotation, {
  start(state, { payload }) {
    delete state.errors.keyRotations;
  },
  success(state, { payload }) {
    delete state.errors.keyRotations;
  },
  error(state, { payload }) {
    state.errors.keyRotations = { error: payload };
  },
});
