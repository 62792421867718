import useResizeObserver from "@react-hook/resize-observer";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useDebounce } from "hooks/ui";
import { CompactMode, EditInputType, EditProps } from "../Constants";
import EditDateCell from "./EditDateCell";
import EditInputCell from "./EditInputCell";
import EditMultiSelectCell from "./EditMultiSelectCell";
import EditSingleSelectCell from "./EditSingleSelectCell";
import { Position } from "./Shared";
import type { TableCellProps } from "../TableUtilities";

const useGetInputPosition = (
  tableEl: HTMLElement | null,
  cellEl: HTMLElement | null,
): Position => {
  const firstRender = useRef(true);
  const [tableRect, setTableRect] = useState<DOMRect>();
  const [cellRect, setCellRect] = useState<DOMRect>();
  const measureTable = useCallback(() => {
    tableEl && setTableRect(tableEl.getBoundingClientRect());
  }, [tableEl]);
  const measureCell = useCallback(() => {
    cellEl && setCellRect(cellEl.getBoundingClientRect());
  }, [cellEl]);
  const measureCellAndTable = useCallback(() => {
    measureCell();
    measureTable();
  }, [measureCell, measureTable]);
  const debouncedMeasureCellAndTable = useDebounce(measureCellAndTable, 10);
  const debouncedMeasureCell = useDebounce(measureCell, 10);

  useLayoutEffect(measureTable, [measureTable]);
  useLayoutEffect(measureCell, [measureCell]);

  if (firstRender.current) {
    firstRender.current = false;
    // Run synchronously to reduce renders without position
    measureTable();
    measureCell();
  }

  useEffect(() => {
    if (debouncedMeasureCell) {
      tableEl?.addEventListener("scroll", debouncedMeasureCell);
    }
    return () => {
      if (debouncedMeasureCell) {
        tableEl?.removeEventListener("scroll", debouncedMeasureCell);
      }
    };
  }, [tableEl, debouncedMeasureCell]);

  useResizeObserver(
    tableEl,
    debouncedMeasureCellAndTable ?? measureCellAndTable,
  );
  useResizeObserver(cellEl, debouncedMeasureCell ?? measureCell);

  const top =
    cellRect && tableRect && tableEl
      ? cellRect.top - tableRect.top + tableEl.scrollTop
      : undefined;
  const left =
    cellRect && tableRect && tableEl
      ? cellRect.left - tableRect.left + tableEl.scrollLeft
      : undefined;
  return {
    width:
      cellRect?.width && left === 0 ? cellRect?.width - 10 : cellRect?.width,
    height: cellRect?.height,
    top,
    left: left === 0 ? 10 : left,
    maxHeight: tableRect && top ? tableRect?.height - top : undefined,
  };
};

const EditCell = (props: {
  targetCellRef: RefObject<HTMLDivElement>;
  editProps: EditProps;
  value: any;
  tableName: string;
  compactMode: CompactMode;
  cellProps: TableCellProps;
}) => {
  const tableRef = useRef<HTMLElement>(
    document?.querySelector(
      `[data-test=table-component-${props.tableName}] tbody`,
    ),
  );

  const inputPosition = useGetInputPosition(
    tableRef?.current,
    props.targetCellRef?.current,
  );

  const propsToPass = useMemo(() => {
    return {
      editProps: props.editProps,
      value: props.value,
      compactMode: props.compactMode,
      cellProps: props.cellProps,
      inputPosition,
    };
  }, [
    inputPosition,
    props.cellProps,
    props.compactMode,
    props.editProps,
    props.value,
  ]);

  switch (props.editProps.editInputType) {
    case EditInputType.Dropdown:
      if (props.editProps.editMultiSelect) {
        return <EditMultiSelectCell {...propsToPass} />;
      }
      return <EditSingleSelectCell {...propsToPass} />;
    case EditInputType.Date:
      return <EditDateCell {...propsToPass} />;
    case EditInputType.Email:
    case EditInputType.Number:
    case EditInputType.Text:
    default:
      return (
        <EditInputCell {...propsToPass} targetCellRef={props.targetCellRef} />
      );
  }
};

export default EditCell;
