import {
  Intent,
  NumericInput,
  IconName,
  InputGroup,
  Button,
  Label,
  Classes,
  ControlGroup,
  TextArea,
} from "@blueprintjs/core";
import Decimal from "decimal.js";
import { debounce } from "lodash";
import React from "react";
import { flushSync } from "react-dom";
import shallowEqual from "shallowequal";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import DynamicSVG from "components/ui/DynamicSVG";
import { ComponentProps } from "legacy/components/designSystems/default/BaseComponent";
import ErrorTooltip from "legacy/components/editorComponents/ErrorTooltip";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { formatNumber, formatPercentage } from "legacy/utils/FormatUtils";
import {
  InputTypes,
  type InputType,
  currencySymbolMap,
} from "legacy/widgets/InputWidget/InputWidgetConstants";
import { styleAsClass } from "styles/styleAsClass";
import { labelStyleRaw } from "../Shared/widgetLabelStyles";

Decimal.set({
  toExpPos: 9e15,
  toExpNeg: -9e15,
});

export const hasIconSupport = (inputType: InputType) => {
  return ![InputTypes.CURRENCY, InputTypes.PASSWORD].includes(inputType);
};

/**
 * All design system component specific logic goes here.
 * Ex. Blueprint has a separate numeric input and text input so switching between them goes here
 * Ex. To set the icon as currency, blue print takes in a set of defined types
 * All generic logic like max characters for phone numbers should be 10, should go in the widget
 */

export const isNumericInput = (inputType: InputType) => {
  return (
    inputType === InputTypes.INTEGER ||
    inputType === InputTypes.NUMBER ||
    inputType === InputTypes.CURRENCY ||
    inputType === InputTypes.PERCENTAGE
  );
};

const NumbericInputWrapperClass = styleAsClass`
  display: flex;
  width: 100%;
  max-height: 36px;
  .currency-prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -1px;
    min-width: 24px;
    flex-shrink: 0;
  }

  .stepper-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: -1px;

    .stepper-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      padding: 0;
      border: 0;
      flex: 1;
      margin-left: -1px;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .stepper-increment {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      svg {
        transform: rotate(180deg);
      }
    }
    .stepper-decrement {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-top: -1px;
    }
  }
`;

const ControlGroupClassName = styleAsClass`
  position: relative;
  overflow: hidden;
  &[data-has-prefix=true] {
    &&&& {
      .${Classes.NUMERIC_INPUT} .${Classes.INPUT_GROUP} .${Classes.INPUT} {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }

  &[data-show-stepper=true] {
    &&&&& {
      .${Classes.NUMERIC_INPUT} .${Classes.INPUT_GROUP} .${Classes.INPUT} {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  &[data-left-icon=true] {
    &&&&&& {
     .${Classes.INPUT} {
        padding-left: 30px;
      }
    }
    
  }

  &[data-right-icon=true] {
    &&&&&& {
      .${Classes.INPUT} {
        padding-right: 30px;
      }
    }
  }

  &[data-multiline=true] {
    .${Classes.POPOVER_TARGET} {
      height: 100%;
    }
    &&&& {
      .${Classes.INPUT} {
        height: 100%;
        max-height: 100%;
      }
    }
    &[data-vertical=false] {
      &&&& {
        .${Classes.INPUT} {
          width: calc(100% - 1px)
        }
      }
    }
  }
  &[data-vertical=true] {
    &&&& {
      label {
        ${labelStyleRaw.vertical}
        flex: 0 0 auto;
        max-width: 100%;
      }
      .${Classes.INPUT_GROUP} {
        width: 100%;
      }
      div {
        margin-top: 0px;
      }
    }
  }
  &:not([data-vertical=true]) {
    &&&& {
      label {
        ${labelStyleRaw.horizontal}
        flex: 0 0 30%;
        max-width: calc(30% - ${String(WIDGET_PADDING)}px);

        text-align: left;
        align-self: flex-start;
      }
      div {
        margin-right: 0px;
      }
    }
  }


  &&&& {
    .${Classes.INPUT_ACTION} .${Classes.BUTTON} {
      margin: 6px;
      background: none;
      box-shadow: none;
      width: 22px;
      min-height: 22px;
    }
    .${Classes.NUMERIC_INPUT} .${Classes.BUTTON_GROUP} {
      display: none;
    }
    .${Classes.INPUT} {
      max-height: 36px;
      width: 100%;
      height: 100%;
    }
    .${Classes.INPUT_GROUP} {
      display: block;
      margin: 0;
    }
    .${Classes.CONTROL_GROUP} {
      justify-content: flex-start;
    }
    height: 100%;

    textarea {
      resize: none;
    }
  }

  .IconWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 99;
  }
`;
class InputComponent extends React.Component<
  InputComponentProps,
  InputComponentState
> {
  textareaRef: React.RefObject<TextArea>;
  numericInputRef: React.RefObject<NumericInput>;
  inputRef: React.RefObject<HTMLInputElement>;
  constructor(props: InputComponentProps) {
    super(props);
    let value = props.value;
    let displayValue = props.value;
    // For TABLE column type, number/percentage/currency are all of inputType as NUMBER
    if (isNumericInput(props.inputType)) {
      value = String(
        props.inputType === InputTypes.PERCENTAGE &&
          props.value !== "" &&
          !isNaN(Number(props.value))
          ? new Decimal(props.value).times(100)
          : props.value,
      );
      value = this.valueWithPrecision(value);
      displayValue = this.formatNumber(value || "");
    }
    this.state = {
      localValue: value,
      displayValue,
      showPassword: false,
      inputFocused: false,
    };
    this.textareaRef = React.createRef<TextArea>();
    this.numericInputRef = React.createRef<NumericInput>();
    this.inputRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount(): void {
    this.ensureFocus();
  }

  componentDidUpdate(prevProps: Readonly<InputComponentProps>) {
    const {
      value,
      inputType,
      defaultValue,
      numberFormatting,
      minimumFractionDigits,
      maximumFractionDigits,
      preventFormattingWhileTyping,
    } = this.props;
    // prevent re-formatting on external value updates when the input is focused
    if (preventFormattingWhileTyping && this.state.inputFocused) return;
    const isPercentInput = inputType === InputTypes.PERCENTAGE;
    if (
      prevProps.numberFormatting !== numberFormatting ||
      prevProps.minimumFractionDigits !== minimumFractionDigits ||
      prevProps.maximumFractionDigits !== maximumFractionDigits ||
      prevProps.inputType !== inputType ||
      prevProps.defaultValue !== defaultValue ||
      prevProps.value !== value
    ) {
      if (isNumericInput(inputType)) {
        const number = String(
          isPercentInput && value !== "" && !isNaN(Number(value))
            ? new Decimal(value).times(100)
            : value,
        );
        const numberWithPrecision = this.valueWithPrecision(number);
        const formatted = this.formatNumber(numberWithPrecision);
        this.setState({
          localValue: numberWithPrecision,
          displayValue: formatted,
        });
      } else {
        this.setState({
          localValue: value,
          displayValue: value,
        });
      }
    }
  }

  componentWillUnmount() {
    this.onDebouncedValueChange.flush();
  }

  shouldComponentUpdate(
    prevProps: Readonly<InputComponentProps>,
    prevState: Readonly<InputComponentState>,
  ) {
    return (
      !shallowEqual(prevProps, this.props) ||
      !shallowEqual(prevState, this.state)
    );
  }

  onDebouncedValueChange = debounce(
    (value: any) => this.props.onValueChange(value),
    this.props.disableDebounce ? 0 : 300,
  );

  getInputDomElement = () => {
    if (isNumericInput(this.props.inputType)) {
      return this.numericInputRef.current?.inputElement;
    } else if (!this.props.multiline) {
      return this.inputRef.current;
    } else {
      return this.textareaRef.current?.textareaElement;
    }
  };

  ensureFocus = () => {
    if (!this.props.autoFocus || this.state.inputFocused) return;

    let inputElement: HTMLInputElement | null | undefined = null;
    if (isNumericInput(this.props.inputType)) {
      inputElement = this.numericInputRef.current?.inputElement;
    } else if (!this.props.multiline) {
      inputElement = this.inputRef.current;
    } else {
      const element = this.textareaRef.current?.textareaElement;
      if (this.state.inputFocused && document.activeElement === element) {
        return;
      }

      if (element && typeof element.setSelectionRange === "function") {
        const endPos = element.value?.length;
        element.focus({ preventScroll: true });
        element.setSelectionRange(endPos, endPos);
      }
    }

    if (!inputElement) return;

    if (this.state.inputFocused && document.activeElement !== inputElement) {
      inputElement.focus({ preventScroll: true });
    }
  };

  onFocus = () => {
    this.setState({ inputFocused: true });
    this.props.onFocusChange(true);

    if (isNumericInput(this.props.inputType)) {
      this.setState({
        displayValue: this.state.localValue,
      });
    }
  };

  onBlur = () => {
    this.setState({ inputFocused: false });

    this.onDebouncedValueChange.flush();
    this.props.onFocusChange(false);

    if (isNumericInput(this.props.inputType)) {
      const valueWithPrecision = this.valueWithPrecision(
        this.state.localValue || "",
      );
      const formatted = this.formatNumber(valueWithPrecision);
      this.setState({
        displayValue: formatted,
        localValue: valueWithPrecision,
      });
    } else {
      this.setState({
        displayValue: this.props.value,
        localValue: this.props.value,
      });
    }
  };

  formatNumber = (value: string) => {
    if (
      this.props.inputType === InputTypes.PERCENTAGE &&
      value !== "" &&
      !isNaN(Number(value))
    )
      return formatPercentage(
        new Decimal(value).div(100).toString(),
        this.props.minimumFractionDigits,
        this.props.maximumFractionDigits,
      );
    return formatNumber(
      value,
      this.props.numberFormatting,
      this.props.minimumFractionDigits,
      this.props.maximumFractionDigits,
    );
  };

  valueWithPrecision = (value: string | number) => {
    const { minimumFractionDigits, maximumFractionDigits, inputType } =
      this.props;
    const isPercent = inputType === InputTypes.PERCENTAGE;
    // show raw value instead of percentage when focused, we need to add two decimal places
    const inRawValueWhenFocused = this.props.widgetType === "TABLE_WIDGET";
    const extraDecimalPlaces = inRawValueWhenFocused ? 2 : 0;
    const valueWithPrecision = formatNumber(
      value,
      "standard",
      isPercent && minimumFractionDigits
        ? Number(minimumFractionDigits) + extraDecimalPlaces
        : minimumFractionDigits,
      isPercent && maximumFractionDigits
        ? Number(maximumFractionDigits) + extraDecimalPlaces
        : maximumFractionDigits,
    );
    return valueWithPrecision.replaceAll(",", "");
  };

  onTextChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = isNumericInput(this.props.inputType)
      ? this.valueWithPrecision(event.target.value)
      : event.target.value;
    this.setState({ localValue: value });
    this.onDebouncedValueChange(value);
  };

  onNumberChange = (valueAsNum: number, valueAsString: string) => {
    this.setState({
      localValue: valueAsString,
      displayValue: valueAsString,
    });
    // change precision first and then div 100 to keep real precision
    const valueWithPrecisionAsString = this.valueWithPrecision(valueAsString);
    // preserve empty string instead of 0 if input is empty
    const numberString =
      this.props.inputType === InputTypes.PERCENTAGE &&
      valueWithPrecisionAsString
        ? String(new Decimal(valueWithPrecisionAsString).div(100))
        : valueWithPrecisionAsString;
    this.onDebouncedValueChange(numberString);
  };

  getIcon(inputType: InputType) {
    switch (inputType) {
      case "PHONE_NUMBER":
        return "phone";
      case "SEARCH":
        return "search";
      case "EMAIL":
        return "envelope";
      default:
        return undefined;
    }
  }

  getType(inputType: InputType) {
    switch (inputType) {
      case "PASSWORD":
        return this.state.showPassword ? "text" : "password";
      case "EMAIL":
        return "email";
      case "SEARCH":
        return "search";
      default:
        return "text";
    }
  }

  onKeyDownTextArea = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const isEnterKey = e.key === "Enter" || e.keyCode === 13;
    const { disableNewLineOnPressEnterKey } = this.props;
    if (isEnterKey && disableNewLineOnPressEnterKey && !e.shiftKey) {
      e.preventDefault();
    }
    if (isEnterKey) {
      this.onDebouncedValueChange.flush();
    }
    if (typeof this.props.onKeyDown === "function") {
      this.props.onKeyDown(e);
    }
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnterKey = e.key === "Enter" || e.keyCode === 13;
    if (isEnterKey) {
      this.onDebouncedValueChange.flush();
    }
    if (typeof this.props.onKeyDown === "function") {
      this.props.onKeyDown(e);
    }
  };

  handleIncrementDecrement = (isIncrementing: boolean) => {
    const { stepSize, inputType } = this.props;
    const isPercentInput = inputType === InputTypes.PERCENTAGE;
    if (stepSize && (stepSize as unknown) !== "" && !isNaN(Number(stepSize))) {
      const currentValue = new Decimal(this.state.localValue || "");
      const updatedNumber = currentValue
        .plus(Number(stepSize) * (isIncrementing ? 1 : -1))
        .toString();

      this.setState({
        localValue: updatedNumber,
        displayValue: this.formatNumber(updatedNumber),
      });
      const valueWithPrecision = this.valueWithPrecision(
        String(
          isPercentInput ? new Decimal(updatedNumber).div(100) : updatedNumber,
        ),
      );
      this.onDebouncedValueChange(valueWithPrecision);
    }
  };

  private getClassNames = () => {
    return `${CLASS_NAMES.INPUT} ${this.props.inputClassName ?? ""} ${
      this.props.isInvalid ? CLASS_NAMES.ERROR_MODIFIER : ""
    } ${this.props.disabled ? CLASS_NAMES.DISABLED_MODIFIER : ""} ${
      this.props.isLoading ? "bp5-skeleton" : ""
    } ${isNumericInput(this.props.inputType) ? Classes.FILL : ""}`;
  };

  private numericInputComponent = () => (
    <div
      className={NumbericInputWrapperClass}
      style={this.props.showStepper ? { marginRight: "1px" } : {}}
    >
      {this.props.inputType === InputTypes.CURRENCY && (
        <div className={`currency-prefix ${CLASS_NAMES.SYSTEM_TEXT}`}>
          <span>
            {this.props.currencyCodeDisplay === "symbol" && this.props.currency
              ? currencySymbolMap[this.props.currency] ?? this.props.currency
              : this.props.currency}
          </span>
        </div>
      )}
      <NumericInput
        value={this.state.displayValue}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        intent={this.props.intent}
        className={this.getClassNames()}
        onValueChange={this.onNumberChange}
        leftIcon={
          this.props.inputType === InputTypes.PHONE_NUMBER
            ? "phone"
            : this.props.leftIcon
        }
        type={this.props.inputType === "PHONE_NUMBER" ? "tel" : undefined}
        stepSize={this.props.stepSize}
        minorStepSize={(this.props.stepSize ?? 1) / 10}
        majorStepSize={(this.props.stepSize ?? 1) * 10}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        ref={this.numericInputRef}
        autoFocus={this.props.autoFocus}
        style={this.props.inputStyleOverride}
      />
      {this.props.showStepper && (
        <div className="stepper-wrapper">
          <button
            aria-label="increment"
            className={`${CLASS_NAMES.SYSTEM_BUTTON} stepper-button stepper-increment`}
            onClick={() => this.handleIncrementDecrement(true)}
          >
            <ChevronDown />
          </button>
          <button
            aria-label="decrement"
            className={`${CLASS_NAMES.SYSTEM_BUTTON} stepper-button stepper-decrement`}
            onClick={() => this.handleIncrementDecrement(false)}
          >
            <ChevronDown />
          </button>
        </div>
      )}
    </div>
  );

  private textAreaInputComponent = () => {
    return (
      <TextArea
        value={this.state.localValue}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        intent={this.props.intent}
        onChange={this.onTextChange}
        className={this.getClassNames()}
        growVertically={this.props.growVertically}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDownTextArea}
        rows={this.props.numLines}
        ref={this.textareaRef}
        style={this.props.inputStyleOverride}
      />
    );
  };

  private textInputComponent = (isTextArea: boolean) =>
    isTextArea ? (
      this.textAreaInputComponent()
    ) : (
      <InputGroup
        autoFocus={this.props.autoFocus}
        value={this.state.localValue}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        intent={this.props.intent}
        onChange={this.onTextChange}
        className={this.getClassNames()}
        style={this.props.inputStyleOverride}
        rightElement={
          this.props.inputType === "PASSWORD" ? (
            <Button
              icon={
                <DynamicSVG
                  iconName={
                    this.state.showPassword ? "visibility_off" : "visibility"
                  }
                  size={16}
                />
              }
              data-test="lock-icon"
              onClick={() => {
                flushSync(() => {
                  this.setState({ showPassword: !this.state.showPassword });
                });
              }}
            />
          ) : undefined
        }
        type={this.getType(this.props.inputType)}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        inputRef={this.inputRef}
      />
    );

  private renderInputComponent = (inputType: InputType, isTextArea: boolean) =>
    isNumericInput(inputType)
      ? this.numericInputComponent()
      : this.textInputComponent(isTextArea);

  private renderIcon = () => {
    return (
      <div
        style={{
          left: this.props.iconPosition === "LEFT" ? "8px" : "",
          right:
            this.props.showStepper && isNumericInput(this.props.inputType)
              ? "30px"
              : this.props.iconPosition === "RIGHT"
              ? "8px"
              : "",
        }}
        className={`IconWrapper ${CLASS_NAMES.ICON} ${
          this.state.inputFocused ? CLASS_NAMES.ACTIVE_MODIFIER : ""
        } ${
          this.props.disabled ||
          this.props.value === "" ||
          this.props.value == null
            ? CLASS_NAMES.DISABLED_MODIFIER
            : ""
        }`}
      >
        <DynamicSVG iconName={this.props.icon} size={18} />
      </div>
    );
  };

  render() {
    const hasIcon = !!this.props.icon && hasIconSupport(this.props.inputType);
    const isTextArea =
      this.props.multiline && !isNumericInput(this.props.inputType);
    return (
      <ControlGroup
        className={ControlGroupClassName}
        data-has-prefix={this.props.inputType === InputTypes.CURRENCY}
        data-show-stepper={this.props.showStepper}
        data-multiline={this.props.multiline}
        data-vertical={this.props.vertical}
        vertical={this.props.vertical}
        fill
        data-left-icon={hasIcon && this.props.iconPosition === "LEFT"}
        data-right-icon={hasIcon && this.props.iconPosition === "RIGHT"}
      >
        {this.props.label ? (
          <Label
            className={`${CLASS_NAMES.ELLIPSIS_TEXT} ${
              this.props.isLoading ? Classes.SKELETON : ""
            } ${this.props.labelClassName ?? CLASS_NAMES.INPUT_LABEL} ${
              this.props.disabled ? CLASS_NAMES.DISABLED_MODIFIER : ""
            }
            `}
            style={this.props.labelStyleOverride}
          >
            {this.props.isRequired && this.props.label.indexOf("*") === -1 && (
              <span className={`asterisk ${CLASS_NAMES.ERROR_MODIFIER}`}>
                *{" "}
              </span>
            )}
            {this.props.label}
          </Label>
        ) : null}
        <>
          {this.props.icon && hasIconSupport(this.props.inputType) ? (
            <div
              style={{
                height: isTextArea ? "100%" : "36px",
                display: "flex",
                alignItems: "center",
                position: "relative",
                maxHeight: isTextArea ? "100%" : "36px",
                width: "100%",
              }}
            >
              {this.props.icon &&
                hasIconSupport(this.props.inputType) &&
                this.renderIcon()}
              {this.renderInputComponent(
                this.props.inputType,
                this.props.multiline,
              )}
            </div>
          ) : (
            this.renderInputComponent(
              this.props.inputType,
              this.props.multiline,
            )
          )}
          <ErrorTooltip
            isOpen={this.props.isInvalid && this.props.showError}
            messages={this.props.errorMessages || ""}
            wrapperClassName={CLASS_NAMES.POPOVER_WRAPPER}
            attachTo={this.getInputDomElement()}
          />
        </>
      </ControlGroup>
    );
  }
}

interface InputComponentState {
  showPassword?: boolean;
  localValue?: string;
  displayValue?: string;
  inputFocused?: boolean;
}

export interface InputComponentProps extends ComponentProps {
  value: string;
  inputType: InputType;
  disabled?: boolean;
  intent?: Intent;
  defaultValue?: string;
  label: string;
  labelClassName?: string;
  labelStyleOverride?: React.CSSProperties;
  inputClassName?: string;
  inputStyleOverride?: React.CSSProperties;
  leftIcon?: IconName;
  allowNumericCharactersOnly?: boolean;
  fill?: boolean;
  errorMessages?: string[];
  maxLength?: number;
  minLength?: number;
  numLines?: number;
  onValueChange: (valueAsString: string) => void;
  vertical?: boolean;
  placeholder?: string;
  isLoading: boolean;
  isRequired?: boolean;
  multiline: boolean;
  isInvalid: boolean;
  showError: boolean;
  onFocusChange: (state: boolean) => void;
  disableNewLineOnPressEnterKey?: boolean;
  onKeyDown?: (
    e:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  autoFocus?: boolean;
  growVertically?: boolean;
  disableDebounce?: boolean;
  showStepper?: boolean;
  stepSize?: number;
  numberFormatting?: Intl.NumberFormatOptions["notation"] | "unformatted";
  minimumFractionDigits?: number | string;
  maximumFractionDigits?: number | string;
  currency?: string;
  currencyCodeDisplay?: "iso_code" | "symbol";
  preventFormattingWhileTyping?: boolean;
  icon?: string;
  iconPosition?: "LEFT" | "RIGHT";
}

export default InputComponent;
