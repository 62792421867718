import { call } from "redux-saga/effects";
import SigningKeyRotationApi, {
  RotationResponse,
} from "legacy/api/SigningKeyRotationApi";
import { ROOT } from "store/utils/types";
import { createSaga } from "../../../utils/saga";
import slice from "../slice";

function* getKeyRotationsInternal() {
  const rotationResponse: RotationResponse = yield call(
    SigningKeyRotationApi.getRotations,
  );
  return rotationResponse;
}

export const getKeyRotations = createSaga(
  getKeyRotationsInternal,
  "getKeyRotations",
  {
    sliceName: "agents",
  },
);

slice.saga(getKeyRotations, {
  start(state, { payload }) {
    delete state.errors.keyRotations;
  },
  success(state, { payload }) {
    delete state.errors.keyRotations;
    if (!payload?.keyRotations) {
      return;
    }
    // set up resource signing state
    state.meta = {
      ...state.meta,
      [ROOT]: {
        ...state.meta[ROOT],
        keyRotations: payload.keyRotations,
      },
    };
  },
  error(state, { payload }) {
    state.errors.keyRotations = { error: payload };
  },
});
