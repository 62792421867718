import { Spinner } from "@blueprintjs/core";
import React, { ReactElement, useMemo } from "react";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { TextAlign } from "legacy/constants/WidgetConstants";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { chooseContrastTextColor } from "legacy/themes/generateColors";
import { GeneratedTheme } from "legacy/themes/types";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { styleAsClass } from "styles/styleAsClass";
import { generateBorderStyleObject } from "../base/generateBorderStyle";
import type {
  PerCornerBorderRadius,
  PerSideBorder,
} from "@superblocksteam/shared/src/types/application";

export type ButtonStyle =
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON"
  | "SUCCESS_BUTTON"
  | "DANGER_BUTTON";

const ButtonClass = styleAsClass`
  width: 100%;
  height: 100%;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  text-align: center;
  overflow: hidden;

  &.text-align-left {
    justify-content: flex-start;

    .button-content {
      margin: 0;
    }
  }

  &.text-align-right {
    justify-content: flex-end;

    .button-content {
      margin: 0;
    }
  }

  &[data-custom-bg="true"]:hover:not(:disabled) {
    box-shadow: var(--hover-box-shadow) !important;
  }

  .button-text {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    max-height: 100%;
  }
  .button-content {
    display: flex;
    align-items: center;
    gap: 4px;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`;

const ButtonLoader = styleAsClass`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  > div {
    height: 100%;
  }
`;

const getClassNameForButtonStyle = (buttonStyle?: ButtonStyle) => {
  switch (buttonStyle) {
    case "PRIMARY_BUTTON":
      return CLASS_NAMES.PRIMARY_BUTTON;
    case "SECONDARY_BUTTON":
      return CLASS_NAMES.SECONDARY_BUTTON;
    case "TERTIARY_BUTTON":
      return CLASS_NAMES.TERTIARY_BUTTON;
    default:
      return CLASS_NAMES.SECONDARY_BUTTON;
  }
};

const getButtonThemeDefaultTextColor = (
  theme: GeneratedTheme,
  buttonStyle?: ButtonStyle,
) => {
  switch (buttonStyle) {
    case "PRIMARY_BUTTON":
      return theme.buttons.primary.textColor.default;
    case "SECONDARY_BUTTON":
      return theme.buttons.secondary.textColor.default;
    case "TERTIARY_BUTTON":
      return theme.buttons.tertiary.textColor.default;
    default:
      return theme.buttons.primary.textColor.default;
  }
};

const getButtonThemeDefaultBackgroundColor = (
  theme: GeneratedTheme,
  buttonStyle?: ButtonStyle,
) => {
  switch (buttonStyle) {
    case "PRIMARY_BUTTON":
      return theme.buttons.primary.backgroundColor.default;
    case "SECONDARY_BUTTON":
      return theme.buttons.secondary.backgroundColor.default;
    case "TERTIARY_BUTTON":
      return theme.buttons.tertiary.backgroundColor.default;
    default:
      return theme.buttons.primary.backgroundColor.default;
  }
};

const getTextAlignmentClass = (textAlignment?: TextAlign) => {
  switch (textAlignment) {
    case TextAlign.LEFT:
      return "text-align-left";
    case TextAlign.RIGHT:
      return "text-align-right";
    default:
      return "text-align-center";
  }
};

interface Props {
  textColor?: string;
  backgroundColor?: string;
  buttonStyle?: ButtonStyle;
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  isLoading: boolean;
  style?: React.CSSProperties;
  useDynamicContrast?: boolean;
  icon?: ReactElement;
  iconPosition?: "LEFT" | "RIGHT";
  textAlignment?: TextAlign;
  width?: "100%" | "auto";
  height?: "100%" | "auto";
  maxWidth?: number;
  minWidth?: number;
  textProps?: {
    textStyleVariant?: string;
    style?: React.CSSProperties;
  };
  border?: PerSideBorder;
  borderRadius?: PerCornerBorderRadius;
}

export const FIT_CONTENT_PADDING = 9;

const getButtonBorderStyleObject = ({
  border,
  borderRadius,
  isLoadingColor,
  buttonStyle,
  isLoading,
  isDisabled,
  fallbackBorderColor,
  backgroundColor,
}: {
  border: PerSideBorder | undefined;
  borderRadius: PerCornerBorderRadius | undefined;
  isLoadingColor: string;
  fallbackBorderColor: string;
  buttonStyle: ButtonStyle | undefined;
  isLoading: boolean;
  isDisabled: boolean;
  backgroundColor: string;
}) => {
  // We use `generateBorderStyleObject` for border styles (width, color, style, and radius).
  // But we then overwrite the border width and color if its not a primary button.
  // That's because only the PRIMARY_BUTTON relies on the BorderPropertyControl
  const borderStyleObject = generateBorderStyleObject({
    border,
    borderRadius,
    fallbackBorderColor,
  });

  if (isLoading) {
    borderStyleObject.borderLeftColor = isLoadingColor;
    borderStyleObject.borderRightColor = isLoadingColor;
    borderStyleObject.borderTopColor = isLoadingColor;
    borderStyleObject.borderBottomColor = isLoadingColor;
  } else if (buttonStyle === "TERTIARY_BUTTON" || isDisabled) {
    borderStyleObject.borderLeftColor = "transparent";
    borderStyleObject.borderRightColor = "transparent";
    borderStyleObject.borderTopColor = "transparent";
    borderStyleObject.borderBottomColor = "transparent";
  } else if (buttonStyle === "SECONDARY_BUTTON") {
    borderStyleObject.borderLeftColor = backgroundColor;
    borderStyleObject.borderRightColor = backgroundColor;
    borderStyleObject.borderTopColor = backgroundColor;
    borderStyleObject.borderBottomColor = backgroundColor;
  }

  if (buttonStyle !== "PRIMARY_BUTTON") {
    // Border width is not supported for non-primary buttons
    // We don't update its `style` because that can't be set yet by users
    borderStyleObject.borderLeftWidth = "1px";
    borderStyleObject.borderRightWidth = "1px";
    borderStyleObject.borderTopWidth = "1px";
    borderStyleObject.borderBottomWidth = "1px";
  }

  return borderStyleObject;
};

const getFallbackBorderColor = (
  buttonStyle: ButtonStyle | undefined,
  backgroundColor: string,
  themeColor: string,
) => {
  if (buttonStyle === "PRIMARY_BUTTON") {
    return backgroundColor;
  } else if (buttonStyle === "SECONDARY_BUTTON") {
    return themeColor;
  }
  return "transparent";
};

export const Button = ({
  textColor: textColorIfSet,
  backgroundColor: backgroundColorIfSet,
  buttonStyle,
  style,
  useDynamicContrast,
  icon,
  iconPosition,
  width,
  height,
  textProps,
  textAlignment,
  border,
  borderRadius,
  ...props
}: Props) => {
  const textAlignmentClass = getTextAlignmentClass(textAlignment);
  const classNames = `${ButtonClass} ${getClassNameForButtonStyle(
    buttonStyle,
  )} ${props.isLoading ? "loading-button" : ""} ${textAlignmentClass}`;
  const theme = useSelector(selectGeneratedTheme);

  // We force a defined backgroundColor and textColor.
  // if the `backgroundColor` and `textColor` are undefined, the button still displays its background and text correctly using the CSS setup for its theme.
  // However, it will take the typography's color instead.
  // We don't want that: we want to honor what the user overrides as a property.
  const backgroundColor =
    backgroundColorIfSet ??
    getButtonThemeDefaultBackgroundColor(theme, buttonStyle);
  const textColor =
    textColorIfSet ?? getButtonThemeDefaultTextColor(theme, buttonStyle);

  const overrideStyles = useMemo(() => {
    const backgroundTinyColor = tinycolor(backgroundColor);

    const borderStyleObject = getButtonBorderStyleObject({
      border,
      borderRadius,
      buttonStyle,
      isLoading: props.isLoading,
      isDisabled: props.disabled ?? false,
      fallbackBorderColor: getFallbackBorderColor(
        buttonStyle,
        backgroundColor,
        theme.colors.primary500,
      ),
      isLoadingColor: theme.colors.neutral100,
      backgroundColor,
    });

    return {
      ...style,
      width: width ?? "100%",
      height: height ?? "100%",
      paddingTop: height === "auto" ? `${FIT_CONTENT_PADDING}px` : undefined, // 9px allows FC height to match default fixed height
      paddingBottom: height === "auto" ? `${FIT_CONTENT_PADDING}px` : undefined,
      maxWidth: props.maxWidth ? `${props.maxWidth}px` : style?.maxWidth,
      minWidth: props.minWidth ? `${props.minWidth}px` : style?.minWidth,
      background:
        buttonStyle === "PRIMARY_BUTTON" && !props.disabled
          ? backgroundColor
          : "",
      cursor: props.disabled
        ? "not-allowed"
        : props.isLoading
        ? "default"
        : "pointer",
      "--hover-box-shadow":
        buttonStyle === "PRIMARY_BUTTON" &&
        backgroundTinyColor &&
        backgroundTinyColor.getAlpha() !== 0
          ? `0px 3px 8px 0px ${backgroundTinyColor
              .setAlpha(0.32)
              .toRgbString()}` // override the theme's box shadow if background color has changed
          : "",
      ...borderStyleObject,
    };
  }, [
    backgroundColor,
    style,
    width,
    height,
    props.maxWidth,
    props.minWidth,
    props.disabled,
    props.isLoading,
    buttonStyle,
    border,
    borderRadius,
    theme.colors.neutral100,
    theme.colors.primary500,
  ]);

  const textClassName = useMemo(() => {
    const typographyClassName =
      textProps?.textStyleVariant !== undefined
        ? getTextCssClassFromVariant(textProps.textStyleVariant)
        : "";

    return `button-text ${typographyClassName}`;
  }, [textProps?.textStyleVariant]);

  const styleForText = useMemo(() => {
    // We overwrite the typography's text color, in favor of the textColor property or its dynamic color
    const style: React.CSSProperties = {
      ...textProps?.style,
      color: textColor,
    };

    if (
      backgroundColor &&
      typeof backgroundColor === "string" &&
      useDynamicContrast &&
      !textColorIfSet
    ) {
      style.color = chooseContrastTextColor(backgroundColor);
    }

    if (props.disabled) {
      style.color = "";
    }

    return style;
  }, [
    useDynamicContrast,
    backgroundColor,
    textProps?.style,
    textColor,
    textColorIfSet,
    props.disabled,
  ]);

  const buttonContent = useMemo(() => {
    if (!icon) {
      return (
        <div className="button-content">
          <span className={textClassName} style={styleForText}>
            {props.text}
          </span>
        </div>
      );
    }
    // force color for icon
    return (
      <div className={"button-content"} style={{ color: textColor }}>
        {iconPosition === "LEFT" && icon}
        {props.text && (
          <span className={textClassName} style={styleForText}>
            {props.text}
          </span>
        )}
        {iconPosition === "RIGHT" && icon}
      </div>
    );
  }, [iconPosition, icon, props.text, textClassName, styleForText, textColor]);

  return (
    <button
      disabled={props.disabled}
      className={classNames}
      style={overrideStyles}
      onClick={props.onClick}
      data-custom-bg={
        buttonStyle === "PRIMARY_BUTTON" && backgroundColor != null
      }
    >
      {buttonContent}
      {props.isLoading && (
        <div
          className={ButtonLoader}
          style={{
            background: theme.colors.neutral100,
          }}
        >
          <Spinner size={20} />
        </div>
      )}
    </button>
  );
};
