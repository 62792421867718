import React, { useCallback, useState } from "react";

import styled from "styled-components";
import { useDebounce } from "hooks/ui";
import CheckboxComponent, {
  StaticTypographyCheckboxLabelProps,
} from "legacy/widgets/CheckboxWidget/CheckboxComponent";
import {
  CellAlignmentTypes,
  SingleCellProperties,
  CompactMode,
  EditProps,
  TABLE_SIZES,
} from "../Constants";
import { CellWrapper } from "../TableStyledWrappers";

const StyledCellWrapper = styled(CellWrapper)`
  ${(props) =>
    props?.cellProperties?.horizontalAlignment === CellAlignmentTypes.RIGHT
      ? "padding-right: 10px;"
      : props?.cellProperties?.horizontalAlignment === CellAlignmentTypes.CENTER
      ? "padding-left: 19px;"
      : ""};
  > div {
    width: unset !important;
  }
`;

const EditCheckboxCell = (props: {
  editProps: EditProps;
  value: any;
  readModeComponent: JSX.Element;
  compactMode: CompactMode;
  cellProperties: SingleCellProperties;
  isFocused: boolean;
}) => {
  const {
    value,
    editProps,
    readModeComponent,
    isFocused: propsIsFocused,
  } = props;
  const { handleOneClickEdit } = editProps;
  const [isFocused, setIsFocused] = useState(false);

  const debouncedToggleFocus = useDebounce(setIsFocused, 50);
  const onMouseEnter = useCallback(() => {
    debouncedToggleFocus && debouncedToggleFocus(true);
  }, [debouncedToggleFocus]);
  const onMouseLeave = useCallback(() => {
    debouncedToggleFocus && debouncedToggleFocus(false);
  }, [debouncedToggleFocus]);

  if (!isFocused && !propsIsFocused) {
    return (
      <div
        data-test={`edit-checkbox-${value}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{
          height: TABLE_SIZES?.[props.compactMode]?.ROW_HEIGHT - 8 ?? "32px",
        }}
      >
        {readModeComponent}
      </div>
    );
  }

  return (
    <StyledCellWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      compactMode={props.compactMode}
      cellProperties={props.cellProperties}
      data-test={`edit-checkbox-active-${value}`}
      style={{
        height: TABLE_SIZES?.[props.compactMode]?.ROW_HEIGHT - 8 ?? "32px",
      }}
    >
      <CheckboxComponent
        isChecked={value}
        isLoading={false}
        onCheckChange={handleOneClickEdit}
        label=""
        widgetId="unused"
        labelProps={StaticTypographyCheckboxLabelProps}
      />
    </StyledCellWrapper>
  );
};

export default EditCheckboxCell;
